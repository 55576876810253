import { useCallback, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ACTIONS, PHASES } from '@utils/enums.js';
import { appState } from '@utils/store.jsx';
import { PhaseContext } from '@context/phase';
import usePrevious from '@hooks/usePrevious.jsx';
import LButton from '../../../../components/button';
import { BetContext } from '../../../../context/bet';
import { FreeBetContext } from '../../../../context/free-bet';
import CashOut from './parts/cashout';
import {
  buttonVariantsMap,
  getNextAction,
  getPrevAction,
  listenAutoCashout,
  playBetSound,
  sendBetMap,
  waitBetPlace,
} from './helpers.js';

const { CANCEL, BET, WAITING, CASH_OUT, FREE } = ACTIONS;
const { GAME_ENDED, BETTING, GAME_STARTED } = PHASES;

const Button = () => {
  const { currency } = appState.provider;

  const phase = useContext(PhaseContext);
  const prevPhase = usePrevious(phase);

  const { isFreeBet, leftSuggestions } = useContext(FreeBetContext);
  const { betId, betValue, action, setAction, setLoading, autoBet, loading, setAutoBet } = useContext(BetContext);
  const isBettingRef = useRef(phase === BETTING);

  useLayoutEffect(() => {
    isBettingRef.current = phase === BETTING;
  }, [phase]);

  const sendBet = useCallback(
    ({ action }) => {
      sendBetMap[action]({ betId });
      if (action === CASH_OUT) return true;
      return waitBetPlace({ betId, action });
    },
    [betId]
  );

  const changeAction = useCallback(
    async ({ action, next, local }) => {
      playBetSound(action);
      setLoading(false);

      const { current: isBetting } = isBettingRef;

      const shouldSend = !local && (isBetting || action === CASH_OUT);
      if (!shouldSend) return setAction(next);

      setLoading(true);
      const isSuccess = await sendBet({ action });
      setLoading(false);

      if (isSuccess) setAction(next);
      else {
        if (!isBetting) return;
        const prev = getPrevAction(next);
        if (prev === BET) setAutoBet(false);
        setAction(prev);
      }
    },
    [setAction, setLoading, sendBet, setAutoBet]
  );

  useEffect(() => {
    if (!autoBet || action !== BET) return;
    void changeAction({ action, next: CANCEL });
  }, [autoBet, action, changeAction]);

  useEffect(() => {
    const AUTO_CASH_OUT = ({ betId: resBetId }) => {
      if (resBetId !== betId) return;
      const params = { action, next: autoBet ? CANCEL : BET, local: true };
      setTimeout(() => void changeAction(params), 100);
    };

    return listenAutoCashout(AUTO_CASH_OUT);
  }, [autoBet, action, betId, changeAction, setLoading]);

  useEffect(() => {
    if (prevPhase === phase) return;

    if (loading && phase === GAME_STARTED) {
      setLoading(false);
      if (action === 'BET') void changeAction({ action, next: CANCEL });
    }

    if (phase === GAME_STARTED && action === CANCEL) void changeAction({ next: CASH_OUT });
    else if (phase === BETTING && action === CANCEL) void changeAction({ action: BET, next: CANCEL });
    else if (phase === GAME_ENDED && action === CASH_OUT) {
      void changeAction({ action, next: autoBet ? CANCEL : BET, local: true });
    }
  }, [loading, setLoading, autoBet, action, changeAction, phase, prevPhase, sendBet, setAction]);

  const onClick = (event) => {
    if (event.detail === 0) return;
    if (action === CANCEL) setAutoBet(false);
    const next = getNextAction({ action, autoBet });
    void changeAction({ action, next });
  };

  if (action === CASH_OUT) return <CashOut onClick={onClick} />;

  const waiting = action === CANCEL && phase !== BETTING;
  const variant = waiting ? WAITING : action === BET && isFreeBet ? FREE : action;
  const disabled = variant === FREE && !leftSuggestions.find(({ betNominal }) => betNominal === betValue);

  return (
    <LButton loading={loading} disabled={disabled} variant={buttonVariantsMap[variant]} onClick={onClick}>
      {betValue?.toFixed(2)} {currency}
    </LButton>
  );
};

export default Button;
