import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { classNames, isBetOutRange, validateBet } from '@utils/helpers';
import { getLocalize } from '@utils/locales';
import Button from '@components/button';
import Typography from '@components/typography';
import Modal from './modal';
import Keys from './parts/keys';
import { getNumberInput } from './helpers';
import styles from './index.module.css';

const l = getLocalize('component.input');

const Keyboard = ({ label, suffix, min, max }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(0);
  const resolveRef = useRef(null);
  const isFirstRef = useRef(true);

  useImperativeHandle(ref, () => ({ open }));

  const open = (defaultValue = 0) => {
    isFirstRef.current = true;
    setIsOpen(true);
    setInputValue(defaultValue.toFixed(2));
    return new Promise((resolve) => (resolveRef.current = resolve));
  };

  const onInput = ({ currentTarget }) => {
    const input = currentTarget.value;
    const value = isFirstRef.current ? '' : inputValue;
    setInputValue(getNumberInput({ inputValue: value, input }));
    isFirstRef.current = false;
  };

  const onSubmit = () => {
    const validatedValue = validateBet({ value: inputValue, min, max });
    setIsOpen(false);
    resolveRef.current(validatedValue);
  };

  const invalid = isBetOutRange(inputValue, min, max);
  const inputClasses = classNames([styles.input, invalid && styles.invalid]);

  const value = (Number(inputValue) || 0).toFixed(2);

  const description = `Min: ${min + ' ' + suffix} - Max: ${max + ' ' + suffix}`;

  return (
    <Modal label={label} description={description} open={isOpen} setOpen={setIsOpen}>
      <div className={styles.container}>
        <div className={inputClasses}>
          <Typography variant="bold" size="20">
            {inputValue}
          </Typography>
        </div>
        <Keys onInput={onInput} />
        <Button onClick={onSubmit}>
          <Typography variant="bold" size="16">
            {l('apply')} {validateBet({ value, min, max }).toFixed(2) + ' ' + suffix}
          </Typography>
        </Button>
      </div>
    </Modal>
  );
};

export default forwardRef(Keyboard);
