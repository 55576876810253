import { io } from 'socket.io-client';
import { randomUUID } from '@utils/helpers';
import { appState } from '@utils/store';

// import msgpackParser from 'socket.io-msgpack-parser';

const getTabId = () => {
  const tabId = sessionStorage.getItem('tab_id') || randomUUID();
  sessionStorage.setItem('tab_id', tabId);
  return tabId;
};

const BASE_URL = import.meta.env.VITE_BASE_URL;

const params = new URLSearchParams(window.location.search);
const token = params.get('token');
const providerId = params.get('providerId');
export const language = params.get('language') || 'ka';
const currency = params.get('currency') || 'GEL';

export const socket = io(BASE_URL, {
  // parser: msgpackParser,
  reconnectionAttempts: 3,
  query: { token, providerId, currency, language, tabId: getTabId() },
  transports: ['websocket'],
});

socket.once('PROVIDER_CONFIGS', (res) => (appState.provider = res));

socket.on('CLIENT_ERRORS', (res) => console.error(res));
